// React
import { useState, useCallback, useEffect } from 'react';

// Styles
import "./Uploader.scss";

const Uploader = (props) => {
    const { label, value, onChange } = props;
    const [ files, setFiles ] = useState(value.length > 0 ? value : [])

    const Thumbnail = (file) => {
        if (file instanceof File) {
            return (<img className="loading" alt="" src={ URL.createObjectURL(file) } />)
        }
        else {
            return (<img className="loading" alt="" src={ file.url } />)
        }
    }

    const handleChange = useCallback((e) => {
        if (e.target.files.length > 0) {
            setFiles([
                ...files,
                e.target.files[0]
            ]);

            onChange({
                value: [
                    ...files,
                    e.target.files[0]
                ]
            });
        }
    }, [ onChange, files ]);

    const removeFile = (index) => {
        if (files[index] instanceof File) {
            const newFiles = files.filter((file, i) => i !== index);
            setFiles(newFiles);

            onChange({
                value: newFiles
            });
        }

        if (files[index] instanceof Object) {
            const newFiles = files.map((file, i) => {
                if (i === index) {
                    return {
                        ...file,
                        is_deleted: true
                    }
                }
                else {
                    return file;
                }
            });

            setFiles(newFiles);

            onChange({
                value: newFiles
            });
        }
    }

    return (
        <div className="uploader">
            { files.length > 0 && files.map((file, index) =>
                <div key={ index } className={ 'thumbnail' + (file instanceof Object && file.is_deleted === true ? ' hidden' : '') }>
                    { Thumbnail(file) }
                    <div className="close" onClick={ () => removeFile(index) }>x</div>
                </div>
            )}
            <div className="add-button">
                <div className="plus">+</div>
                <input name="photos" type="file" onChange={ handleChange } />
            </div>
        </div>
    );
};

export default Uploader;