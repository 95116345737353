// React
import { useState, useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import Datetime from '../../../components/Grid/Cell/Datetime';
import Chips from '../../../components/Grid/Cell/Chips';
import Images from '../../../components/Grid/Cell/Images';

// Components
import Title from '../../../components/Page/Title';
import { DropdownFilterCell } from "../../../components/Grid/Filter/Dropdown";

const IssuesList = () => {
    const navigate = useNavigate();
    const { user } = useContext(GlobalContext);
    const [ issues, setIssues ] = useState([]);
    const [ selectedTab, switchTab ] = useState('normal');

    const initialDataState = {
        filter: undefined,
        sort: [
            {
                field: "id",
                dir: "desc"
            }
        ]
    };
    const [ dataState, setDataState ] = useState(initialDataState);
    const [ dataResult, setDataResult ] = useState(
        process(issues, dataState)
    );

    useEffect(()=> {
        let params = '';
        if (selectedTab === 'pums') {
            params = '?' + new URLSearchParams({
                pums: true
            });
        }
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/issues" + params, {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setIssues(response.data.issues);
                setDataResult(process(response.data.issues, dataState));
    });
    }, [ user, setIssues, dataState, selectedTab ])

    const User = (props) => {
        let user = '-';
        if (props.dataItem.user) {
            user = props.dataItem.user.first_name + ' ' + props.dataItem.user.last_name;
        }
        return (
            <td style={{ whiteSpace: 'nowrap' }}>{ user }</td>
        );
    }

    const Coordinates = (props) => {
        let coordinates = '-';
        if (props.dataItem.lat && props.dataItem.lon) {
            coordinates = (
                <>
                    Lat: { props.dataItem.lat.toFixed(5) }<br />Lon: { props.dataItem.lon.toFixed(5) }
                </>
            );
        }
        return (
            <td style={{ whiteSpace: 'nowrap' }}>{ coordinates }</td>
        );
    }

    const Text = (props) => {
        let text = '-';
        if (props.dataItem.text) {
            if (props.dataItem.text.length > 100) {
                text = props.dataItem.text.substr(0, 99) + '...';
            }
            else {
                text = props.dataItem.text;
            }
        }
        return (
            <td>{ text }</td>
        );
    }

    const Status = (props) => {
        let label = '-';
        let themeColor = 'base';
        switch (props.dataItem.status) {
            case 'approved':
                label = 'Approvata';
                themeColor = 'success';
                break;

            case 'pending':
                label = 'In attesa';
                themeColor = 'warning';
                break;

            case 'rejected':
                label = 'Rifiutata';
                themeColor = 'error';
                break;

            default:
                label = '-';
                themeColor = 'base';
        }

        return (
            <td><Chip text={ label } themeColor={ themeColor } rounded={ null } /></td>
        )
    }

    const StatusFilterCell = (props) => (
        <DropdownFilterCell
            { ...props }
            labels={[ 'In Attesa', 'Approvata', 'Rifiutata' ]}
            data={[ 'pending', 'approved', 'rejected']}
        />
    );

    const Actions = (props) => {
        return (
            <td>
                <Button className="buttons-container-button" icon="hyperlink-open" onClick={ () => openIssue(props.dataItem.id) }></Button>
            </td>
        )
    }

    const openIssue = (issue_id) => {
        navigate("/issues/" + issue_id);
    }

    return (
        <>
            <div className="tabs">
                <div className={ 'tab' + ( selectedTab === 'pums' ? ' selected' : '') } onClick={ () => switchTab('pums') } >Segnalazioni PUMS 2024</div>
                <div className={ 'tab' + ( selectedTab === 'normal' ? ' selected' : '') } onClick={ () => switchTab('normal') } >Segnalazioni Ordinarie</div>
            </div>
            <Title>Lista segnalazioni <small>({ issues.length })</small></Title>
            <div className="section">
                <Grid
                    scrollable="none"
                    sortable={ true }
                    filterable={ true }
                    data={dataResult}
                    {...dataState}
                    onDataStateChange={(e) => {
                        setDataState(e.dataState);
                        setDataResult(process(issues, e.dataState));
                    }}
                >
                    <GridColumn field="id" title="Id" width="40px" filterable={ false } />
                    <GridColumn field="categories" title="Categorie" filterable={ false } cell={ Chips } />
                    <GridColumn field="created_at" title="Creata il" filterable={ false } cell={ Datetime } />
                    <GridColumn field="user" title="Segnalatore" filterable={ false } cell={ User } />
                    <GridColumn field="zone.name" title="Zona" filterable={ false } />
                    <GridColumn field="location_display_name" title="Toponimo" filterable={ false } />
                    <GridColumn field="coordinates" title="Coordinate" filterable={ false } cell={ Coordinates } />
                    <GridColumn field="photos" title="Foto" filterable={ false } cell={ Images } />
                    <GridColumn field="text" title="Testo" filterable={ false } cell={ Text } />
                    <GridColumn field="status" title="Stato" cell={ Status } filter="boolean" filterCell={ StatusFilterCell } />
                    <GridColumn field="actions" title="&nbsp;" filterable={ false } cell={ Actions } />
                </Grid>
            </div>
        </>
    );
}

export default IssuesList;
