// React
import { useState, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

// Kendo
import { Button } from "@progress/kendo-react-buttons";
import { useDraggable, Icon } from "@progress/kendo-react-common";

// Icons
import { ReactComponent as IssuesIcon } from '../../assets/icons/issues.svg'
import { ReactComponent as ParkingsIcon } from '../../assets/icons/parkings.svg'
import { ReactComponent as PharmaciesIcon } from '../../assets/icons/pharmacies.svg'
import { ReactComponent as TreesIcon } from '../../assets/icons/trees.svg'
import { ReactComponent as CleaningIcon } from '../../assets/icons/cleaning.svg'
import { ReactComponent as EventsIcon } from '../../assets/icons/events.svg'

const HOLD_DELAY = 200;
export const DraggableIcon = (props) => {
    const navigate = useNavigate();
    const [ pressed, setPressed] = useState(false);
    const [ dragged, setDragged] = useState(false);
    const [ initial, setInitial ] = useState(null);
    const dragTimeout = useRef();
    const button = useRef(null);

    const icons = {
        'issues': <IssuesIcon />,
        'pharmacies': <PharmaciesIcon />,
        'trees': <TreesIcon />,
        'parkings': <ParkingsIcon />,
        'cleaning': <CleaningIcon />,
        'events': <EventsIcon />
    }

    const handlePress = useCallback(() => {
        dragTimeout.current = window.setTimeout(() => {
            setPressed(true);
        }, HOLD_DELAY);
    }, []);

    const handleDragStart = useCallback((event) => {
        if (pressed) {
            setDragged(true);
            setInitial({
                x: event.clientX,
                y: event.clientY,
            });
        }
    }, [ pressed ]);

    const handleDrag = useCallback((event) => {
        if (dragTimeout.current) {
            window.clearTimeout(dragTimeout.current);
        }
        if (!button.current || !button.current.element || !initial) {
            return;
        }
        const transform = `translate(${event.clientX - initial.x}px, ${
            event.clientY - initial.y
        }px)`;
        button.current.element.style.transition = "none";
        button.current.element.style.transform = transform;
    }, [ initial ]);

    const handleDragEnd = useCallback(() => {
        if (!button.current || !button.current.element) {
            return;
        }
        button.current.element.style.transition = "transform .3s ease-in-out";
        button.current.element.style.transform = null;
        setDragged(false);
        setInitial(null);
    }, []);

    const handleRelease = useCallback(() => {
        if (dragTimeout.current) {
            window.clearTimeout(dragTimeout.current);
        }

        if (pressed === false) {
            navigate("/" + props.icon);
        }

        setPressed(false);
    }, [ pressed, navigate, props ]);

    useDraggable(button, {
        onPress: handlePress,
        onDragStart: handleDragStart,
        onDrag: handleDrag,
        onDragEnd: handleDragEnd,
        onRelease: handleRelease,
    });

    return (
        <Button className="icon" ref={ button }>
            { props.icon ? icons[props.icon] : null }
            { props.label ? <div className="label">{ props.label }</div> : null  }
        </Button>
    );
};
