// React
import { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NavLink } from "react-router-dom";

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Sidebar from '../../components/Sidebar/Sidebar';

// Pages
import CleaningList from './pages/List';

const CleaningModule = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);

    useEffect(()=> {
        if (!currentModule || currentModule.id !== 'cleaning') {
            setCurrentModule({
                id: 'cleaning',
                name: 'Igiene Urbana'
            });
        }
    }, [ currentModule, setCurrentModule ]);

    return (
        <>
            <Sidebar label={ currentModule?.name }>
                <div className="module-menu">
                    <ul className="section">
                        <li>Igiene Urbana</li>
                        <ul>
                            <li>
                                <NavLink to="/cleaning/collection_point" end>Isole ecologiche</NavLink>
                            </li>
                            <li>
                                <NavLink to="/cleaning/unsorted" end>Cestini</NavLink>
                            </li>
                            <li>
                                <NavLink to="/cleaning/glass" end>Campane del vetro</NavLink>
                            </li>
                            <li>
                                <NavLink to="/cleaning/vegetable_oil" end>Olio vegetale</NavLink>
                            </li>
                            <li>
                                <NavLink to="/cleaning/clothes" end>Vestiti</NavLink>
                            </li>
                            <li>
                                <NavLink to="/cleaning/batteries" end>Batterie</NavLink>
                            </li>
                            <li>
                                <NavLink to="/cleaning/dog_waste" end>Deiezioni canine</NavLink>
                            </li>
                        </ul>
                    </ul>
                </div>
            </Sidebar>

            <div className="page">
                <Routes>
                    <Route path="" element={ <CleaningList /> } />
                    <Route path="/:cleaningCategory" element={ <CleaningList /> } />
                </Routes>
            </div>
        </>
    );
}

export default CleaningModule;
