// React
import { useState, useContext, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Polygon } from 'react-leaflet';

// Kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button, Chip } from "@progress/kendo-react-buttons";
import { process } from "@progress/kendo-data-query";
import Datetime from '../../../components/Grid/Cell/Datetime';

// Components
import Title from '../../../components/Page/Title';
import Field from '../../../components/Page/Field';

// Styles
import './Zones.scss';

const ZonesPage = () => {
    const { user } = useContext(GlobalContext);
    const [ zones, setZones ] = useState([]);
    const palette = [ '#90e0f3', '#faa916', '#9a031e', '#2e294e', '#9ce37d' ];

    const initialDataState = {
        filter: undefined,
        sort: [
            {
                field: "id",
                dir: "asc"
            }
        ]
    };
    const [ dataState, setDataState ] = useState(initialDataState);
    const [ dataResult, setDataResult ] = useState(
        process(zones, dataState)
    );

    useEffect(()=> {
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/zones", {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
        .then((response) => response.json())
        .then((response) => {
            setZones(response.data.zones);
            setDataResult(process(response.data.zones, dataState));
        });
    }, [ user, setZones, dataState ])

    const Actions = (props) => {
        return (
            <td>
            </td>
        )
    }

    const Color = (props) => {
        return (
            <td className="k-text-center">
                <div className="sample-color" style={{ borderColor: palette[props.dataIndex], backgroundColor: palette[props.dataIndex] + '80' }}></div>
            </td>
        )
    }

    return (
        <>
            <Title>Zone</Title>
            <div className="section grid-template-two k-h-full">
                <div className="left">
                    <Grid
                        className="k-mt-6"
                        scrollable="none"
                        data={ dataResult }
                        { ...dataState }
                    >
                        <GridColumn field="id" title="Id" width="40px" filterable={ false } />
                        <GridColumn field="name" title="Nome" filterable={ false } />
                        <GridColumn field="color" title="Colore" filterable={ false } cell={ Color } />
                        <GridColumn field="created_at" title="Creata il" filterable={ false } cell={ Datetime } />
                        <GridColumn field="actions" title="&nbsp;" filterable={ false } cell={ Actions } />
                    </Grid>
                </div>

                <div className="right">
                    <Field label="Mappa" className="k-h-full">
                        <MapContainer center={[ 45.3140617, 9.4998361 ]} zoom={ 13 } scrollWheelZoom={ true } zoomControl={ false }>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                            { zones && zones.map((zone, index) => {
                                return (
                                    <Polygon key={ index } pathOptions={{ weight: 1, color: palette[index], fillOpacity: 0.6 }} positions={ zone.geojson.coordinates } />
                                )
                            })}
                        </MapContainer>
                    </Field>
                </div>
            </div>
        </>
    );
}

export default ZonesPage;
