// React
import { useContext, useEffect } from 'react';
import { useHref } from 'react-router-dom';

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Topbar from '../../components/Topbar/Topbar';

export default function ProtectedRoute({ children }) {
    const { user, setUser } = useContext(GlobalContext);
    const basename = useHref('/');

    useEffect(()=> {
        if (!user) {
            const userData = localStorage.getItem("user");
            if (!user && userData) {
                setUser(JSON.parse(userData));
            }
            else {
                window.location.href = basename;
            }
        }
    }, [ user, setUser, basename ]);

    if (!user) {
        return <></>
    }
    else {
        return (
            <>
                <Topbar />
                <div id="middle">
                    { children }
                </div>
            </>
        )
    }
};
