// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sample-color {
  width: 80px;
  height: 25px;
  border-width: 2px;
  border-style: solid;
  margin: 0 auto;
}`, "",{"version":3,"sources":["webpack://./src/modules/issues/pages/Zones.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,cAAA;AACJ","sourcesContent":[".sample-color {\r\n    width: 80px;\r\n    height: 25px;\r\n    border-width: 2px;\r\n    border-style: solid;\r\n    margin: 0 auto;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
