// Kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";

// Components
import Label from './Label';

export default function Field(props) {
    let selectedValue = null;
    if (props.type === 'select') {
        selectedValue = props.items.find((item) => item.value === props.value);
    }

    return (
        <div className={ 'field ' + ( props.className ? props.className : '' ) }>
            { props.label &&
                <Label>{ props.label }</Label>
            }

            { props.type === 'images' &&
                <div className="k-d-grid k-grid-cols-3 k-gap-5">
                    { props.items.map((photo, index) =>
                        <img key={ index } className="k-max-w-full" src={ photo.url } alt="" onClick={ () => props.onImageClick ? props.onImageClick(photo) : null } />
                    )}
                </div>
            }

            { props.type === 'select' &&
                <DropDownList
                    name={ props.name }
                    data={ props.items }
                    value={ selectedValue }
                    dataItemKey="value"
                    textField="label"
                    rounded="null"
                    onChange={ props.onChange }
                    valueMap={ item => item && item.value }
                />
            }

            { props.type === 'text' &&
                <div className={ "text" + ( props.multiline ? ' multiline' : '' ) }>{ props.value }</div>
            }

            { props.children }
        </div>
    )
};
