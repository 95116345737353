// React
import { useState, useEffect } from 'react';

// Kendo
import { Fade } from "@progress/kendo-react-animation";

export default function Success(props) {
    const [ show, setShow ] = useState(false);
    const children = show ? <div id="success"><span className="k-icon k-i-check k-icon-xxxl" style={{ color: "white" }}></span></div> : null;

    useEffect(()=> {
        if (props.enter === 'success') {
            setShow(true);
            setTimeout(() => {
                setShow(false);
                props.exit(null);
            }, 600);
        }
    }, [ show, setShow, props ])

    return (
        <Fade transitionEnterDuration={ 300 } transitionExitDuration={ 300 }>
            { children }
        </Fade>
    )
};
