// React
import { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NavLink } from "react-router-dom";

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Sidebar from '../../components/Sidebar/Sidebar';

// Pages
import ParkingsList from './pages/List';
import ParkingPage from './pages/Parking';

const ParkingsModule = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);

    useEffect(()=> {
        if (!currentModule || currentModule.id !== 'parkings') {
            setCurrentModule({
                id: 'parkings',
                name: 'Parcheggi'
            });
        }
    }, [ currentModule, setCurrentModule ]);

    return (
        <>
            <Sidebar label={ currentModule?.name }>
                <div className="module-menu">
                    <ul className="section">
                        <li>Gestione Segnalazioni</li>
                        <ul>
                            <li>
                                <NavLink to="/parkings" end>Lista parcheggi</NavLink>
                            </li>
                        </ul>
                    </ul>
                </div>
            </Sidebar>

            <div className="page">
                <Routes>
                    <Route path="" element={ <ParkingsList /> } />
                    <Route path="/:parkingId" element={ <ParkingPage /> } />
                </Routes>
            </div>
        </>
    );
}

export default ParkingsModule;
