// React
import { cloneElement } from 'react';

// Kendo
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Button } from "@progress/kendo-react-buttons";

export const DropdownFilterCell = (props) => {
    let hasValue = (value) => Boolean(value && value !== props.defaultItem);
    const onChange = (event) => {
        hasValue = hasValue(event.target.value);
            props.onChange({
            value: hasValue ? event.target.value : "",
            operator: hasValue ? "eq" : "",
            syntheticEvent: event.syntheticEvent,
        });
    };

    const onClearButtonClick = (event) => {
        event.preventDefault();
        props.onChange({
            value: "",
            operator: "",
            syntheticEvent: event,
        });
    };

    const itemRender = (li, itemProps) => {
        const itemChildren = (
            <span>{ props.labels[itemProps.index] }</span>
        );
        return cloneElement(li, li.props, itemChildren);
    };

    const valueRender = (element, value) => {
        if (!value) {
            return element;
        }
        const children = (
            <span>{ props.labels[props.data.indexOf(value)] }</span>
        );
        return cloneElement(element, {...element.props }, children);
    };

    return (
        <div className="k-filtercell">
            <DropDownList
                data={ props.data }
                onChange={ onChange }
                value={ props.value || props.defaultItem }
                defaultItem={ props.defaultItem }
                itemRender={ itemRender }
                valueRender={ valueRender }
            />
            <Button
                title="Cancella"
                disabled={ !hasValue(props.value) }
                onClick={ onClearButtonClick }
                icon="filter-clear"
            />
        </div>
    );
};