// React
import React from 'react';
import { useState, useContext } from 'react';
import { useTranslation } from "react-i18next";

// KendoUI
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Error } from '@progress/kendo-react-labels';
import { Input } from '@progress/kendo-react-inputs';

// Global Context
import { GlobalContext } from '../../contexts/Global';

const Login = () => {
    const { t } = useTranslation();
    const { user, setUser } = useContext(GlobalContext);
    const [ error, setError ] = useState(null);

    const handleSubmit = (dataItem) => {
        setError(null);
        fetch(process.env.REACT_APP_API_BASE_URL + '/user/login', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(dataItem)
        })
        .then((response) => response.json())
        .then((response) => {
            if (response.status === 'success') {
                if (setUser) {
                    localStorage.setItem("user", JSON.stringify(response.data.user));
                    setUser(response.data.user);
                }
            }
            else {
                setError(response.message)
            }
        })
        .catch((err) => {
            setError(err.message);
        });
    }

    return (
        <div className="login" style={{ backgroundImage: 'url("/img/backgrounds/bgr0' + (Math.floor(Math.random() * 4) + 1) + '.jpg")' }}>
            <div className="box k-flex-layout k-flex-col k-justify-content-between">
                <div className="logo">
                    <img src="/img/logo/logo.png" />
                </div>
                <div className="welcome">
                    <p dangerouslySetInnerHTML={{ __html: t('login_welcome_message') }}></p>
                </div>
                <div className="form">
                    <Form
                        onSubmit={ handleSubmit }
                        render={(formRenderProps) => (
                            <FormElement>
                                <Field
                                    name="email"
                                    label={ t('email') }
                                    component={ Input }
                                    required
                                />

                                <Field
                                    name="password"
                                    type="password"
                                    label={ t('password') }
                                    component={ Input }
                                    required
                                />

                                { error &&
                                    <Error>{ error }</Error>
                                }

                                <div className="k-form-buttons k-justify-content-end">
                                    <button type="submit" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">
                                        { t('login') }
                                    </button>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
            </div>
        </div>
    )
}

export default Login;