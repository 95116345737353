// React
import { useContext, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import { NavLink } from "react-router-dom";

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Sidebar from '../../components/Sidebar/Sidebar';

const EventsModule = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);

    useEffect(()=> {
        if (!currentModule || currentModule.id !== 'events') {
            setCurrentModule({
                id: 'events',
                name: 'Eventi'
            });
        }
    }, [ currentModule, setCurrentModule ]);

    return (
        <>
            <Sidebar label={ currentModule?.name }>
                <div className="module-menu">
                    <ul className="section">
                        <li>Eventi</li>
                        <ul>
                            <li>
                                <NavLink to="/events/bins" end>Lista eventi</NavLink>
                            </li>
                        </ul>
                    </ul>
                </div>
            </Sidebar>

            <div className="page">
            </div>
        </>
    );
}

export default EventsModule;
