// React
import { useState, useContext, useEffect  } from 'react';

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Kendo
import { Button } from "@progress/kendo-react-buttons";
import {
    Chart,
    ChartLegend,
    ChartSeries,
    ChartSeriesItem,
    ChartSeriesLabels,
    ChartCategoryAxis,
    ChartCategoryAxisItem,
    ChartCategoryAxisTitle,
} from "@progress/kendo-react-charts";

// Components
import Title from '../../../components/Page/Title';
import { ReactComponent as PdfIcon } from '../../../assets/icons/pdf.svg'
import { ReactComponent as ExcelIcon } from '../../../assets/icons/excel.svg'

// Styles
// import './Export.scss';

const ExportPage = () => {
    const { user } = useContext(GlobalContext);
    const [ byCategory, setByCategory ] = useState(null);
    const [ byZone, setByZone ] = useState(null);
    const [ byStatus, setByStatus ] = useState(null);
    const [ timeline, setTimeline ] = useState({
        days: [],
        values: []
    });
    const [ selectedTab, switchTab ] = useState('pums');

    useEffect(()=> {
        let params = '';
        if (selectedTab === 'pums') {
            params = '?' + new URLSearchParams({
                pums: true
            });
        }

        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/issues/charts" + params, {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
        .then((response) => response.json())
        .then((response) => {
            setByCategory(response.data.by_category);
            setByZone(response.data.by_zone);
            setByStatus(response.data.by_status);

            let t = {
                days: response.data.timeline.map(x => x.label),
                values: response.data.timeline.map(x => x.value)
            }

            setTimeline(t);
        });
    }, [ setByCategory, setByZone, setByStatus, setTimeline, user, selectedTab ])

    const labelContent = (e) => {
        return e.category + ' \n ' + (e.value * 100).toFixed(1) + '%'
    }

    return (
        <>
            <div className="tabs">
                <div className={ 'tab' + ( selectedTab === 'pums' ? ' selected' : '') } onClick={ () => switchTab('pums') } >Segnalazioni PUMS 2024</div>
                <div className={ 'tab' + ( selectedTab === 'normal' ? ' selected' : '') } onClick={ () => switchTab('normal') } >Segnalazioni Ordinarie</div>
            </div>
            <Title>
                Esporta segnalazioni
                <a className="k-float-right" href={ process.env.REACT_APP_API_BASE_URL + '/admin/issues/export/excel/' + selectedTab + '?token=' + user.access_token }>
                    <Button rounded={ null } size="large" themeColor="primary">
                        Esporta per Excel <ExcelIcon />
                    </Button>
                </a>
            </Title>
            <div className="section grid-template-three">
                <div className="left">
                    <h2>
                        Segnalazioni per categoria
                        <a target="_blank" rel="noreferrer" href={ process.env.REACT_APP_API_BASE_URL + '/admin/issues/export/pdf/' + selectedTab + '/by_category?token=' + user.access_token }>
                            <PdfIcon />
                        </a>
                    </h2>
                    <Chart>
                        <ChartSeries>
                            <ChartSeriesItem type="donut" data={ byCategory } categoryField="label" field="share" size={50} autoFit="true">
                                <ChartSeriesLabels color="#000" background="none" content={ labelContent } position="outsideEnd" align="circle" />
                            </ChartSeriesItem>
                        </ChartSeries>
                        <ChartLegend visible={ false } />
                    </Chart>
                </div>
                <div className="center">
                    <h2>
                        Segnalazioni per zona
                        { selectedTab === 'pums' &&
                            <a target="_blank" rel="noreferrer" href={ process.env.REACT_APP_API_BASE_URL + '/admin/issues/export/pdf/' + selectedTab + '/by_zone?token=' + user.access_token }>
                                <PdfIcon />
                            </a>
                        }
                    </h2>
                    <Chart>
                        <ChartSeries>
                            <ChartSeriesItem type="donut" data={ byZone } categoryField="label" field="share" size={ 50 } autoFit="true">
                                <ChartSeriesLabels color="#000" background="none" content={ labelContent } position="outsideEnd" align="circle" />
                            </ChartSeriesItem>
                        </ChartSeries>
                        <ChartLegend visible={ false } />
                    </Chart>
                </div>
                <div className="right">
                    <h2>Segnalazioni per stato di moderazione</h2>
                    <Chart seriesColors={[ "#b9e5a6", "#ffd040", "#f65140" ]}>
                        <ChartSeries>
                            <ChartSeriesItem type="donut" data={ byStatus } categoryField="label" field="share" size={ 50 } autoFit="true">
                                <ChartSeriesLabels color="#000" background="none" content={ labelContent } position="outsideEnd" align="circle" />
                            </ChartSeriesItem>
                        </ChartSeries>
                        <ChartLegend visible={false} />
                    </Chart>
                </div>
                <div className="bottom">
                    <h2>Timeline</h2>
                    <Chart>
                        <ChartCategoryAxis>
                            <ChartCategoryAxisItem
                                type="date"
                                categories={ timeline.days }
                                baseUnit="days"
                                labels={{
                                    format: 'd MMM y',
                                    rotation: 'auto'
                                }}
                                maxDivisions={ 60 }>
                                <ChartCategoryAxisTitle text="Tempo" />
                            </ChartCategoryAxisItem>
                        </ChartCategoryAxis>
                        <ChartSeries>
                            <ChartSeriesItem type="column" gap={ 0 } spacing={ 0 } data={ timeline.values } color="#7FDBFF" />
                        </ChartSeries>
                    </Chart>
                </div>
            </div>
        </>
    );
}

export default ExportPage;
