// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.emails {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.emails .email {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  border-bottom: 1px solid;
  border-color: var(--lightgray);
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.emails .email:last-child {
  border-bottom: none;
}
.emails .email > .date {
  flex-grow: 1;
}
.emails .email .icon {
  max-width: 20px;
}
.emails .email .type {
  min-width: 40%;
}`, "",{"version":3,"sources":["webpack://./src/modules/issues/pages/Issue.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACI;EACI,WAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;EACA,wBAAA;EACA,8BAAA;EACA,oBAAA;EACA,mBAAA;AACR;AACQ;EACI,mBAAA;AACZ;AAEQ;EACI,YAAA;AAAZ;AAGQ;EACI,eAAA;AADZ;AAIQ;EACI,cAAA;AAFZ","sourcesContent":[".emails {\r\n    width: 100%;\r\n    display: flex;\r\n    flex-direction: column;\r\n\r\n    .email {\r\n        width: 100%;\r\n        display: flex;\r\n        flex-direction: row;\r\n        gap: 10px;\r\n        align-items: center;\r\n        border-bottom: 1px solid;\r\n        border-color: var(--lightgray);\r\n        padding-bottom: 10px;\r\n        margin-bottom: 10px;\r\n\r\n        &:last-child {\r\n            border-bottom: none;\r\n        }\r\n\r\n        > .date {\r\n            flex-grow: 1;\r\n        }\r\n\r\n        .icon {\r\n            max-width: 20px;\r\n        }\r\n\r\n        .type {\r\n            min-width: 40%;\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
