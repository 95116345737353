// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sidebar {
  width: 288px;
  min-width: 288px;
  height: 100%;
  padding: 36px 24px 36px 36px;
}
#sidebar .module-label.dashboard {
  height: 108px;
}
#sidebar .module-menu {
  font-size: 11px;
  font-weight: 400;
}
#sidebar .module-menu .section {
  list-style-type: none;
  padding: 0;
  margin: 0;
  margin-top: 12px;
}
#sidebar .module-menu .section > li {
  padding: 0px 10px;
  color: #cecec6;
  line-height: 30px;
}
#sidebar .module-menu .section > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
#sidebar .module-menu .section > ul > li {
  color: #423830;
  margin-bottom: 1px;
}
#sidebar .module-menu .section > ul > li > a {
  padding: 0px 10px 0px 22px;
  line-height: 23px;
  display: inline-block;
  color: inherit;
}
#sidebar .module-menu .section > ul > li > a.active, #sidebar .module-menu .section > ul > li > a:hover {
  background-color: #423830;
  color: #fff;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/Sidebar/Sidebar.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,YAAA;EACA,4BAAA;AACJ;AAEQ;EACI,aAAA;AAAZ;AAII;EACI,eAAA;EACA,gBAAA;AAFR;AAIQ;EACI,qBAAA;EACA,UAAA;EACA,SAAA;EACA,gBAAA;AAFZ;AAIY;EACI,iBAAA;EACA,cAAA;EACA,iBAAA;AAFhB;AAKY;EACI,qBAAA;EACA,UAAA;EACA,SAAA;AAHhB;AAKgB;EACI,cAAA;EACA,kBAAA;AAHpB;AAKoB;EACI,0BAAA;EACA,iBAAA;EACA,qBAAA;EACA,cAAA;AAHxB;AAKwB;EAEI,yBAAA;EACA,WAAA;EACA,qBAAA;AAJ5B","sourcesContent":["#sidebar {\r\n    width: 288px;\r\n    min-width: 288px;\r\n    height: 100%;\r\n    padding: 36px 24px 36px 36px;\r\n\r\n    .module-label {\r\n        &.dashboard {\r\n            height: 108px;\r\n        }\r\n    }\r\n\r\n    .module-menu {\r\n        font-size: 11px;\r\n        font-weight: 400;\r\n\r\n        .section {\r\n            list-style-type: none;\r\n            padding: 0;\r\n            margin: 0;\r\n            margin-top: 12px;\r\n\r\n            > li {\r\n                padding: 0px 10px;\r\n                color: #cecec6;\r\n                line-height: 30px;\r\n            }\r\n\r\n            > ul {\r\n                list-style-type: none;\r\n                padding: 0;\r\n                margin: 0;\r\n\r\n                > li {\r\n                    color: #423830;\r\n                    margin-bottom: 1px;\r\n\r\n                    > a {\r\n                        padding: 0px 10px 0px 22px;\r\n                        line-height: 23px;\r\n                        display: inline-block;\r\n                        color: inherit;\r\n\r\n                        &.active,\r\n                        &:hover {\r\n                            background-color: #423830;\r\n                            color: #fff;\r\n                            text-decoration: none;\r\n                        }\r\n                    }\r\n                }\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
