// React
import React, { createContext, useState } from 'react';

// Components
import Loader from '../components/Loader/Loader';

export const GlobalContext = createContext()

const GlobalContextProvider = (props) => {
    const [ user, setUser ] = useState(null);
    const [ currentModule, setCurrentModule ] = useState(null);
    const [ isLoading, showLoader ] = useState(false);

    return (
        <GlobalContext.Provider value={{ user, setUser, currentModule, setCurrentModule, isLoading, showLoader }}>
            { isLoading && (<Loader />) }
            { props.children }
        </GlobalContext.Provider>
    )
}
export default GlobalContextProvider
