// React
import { useState, useCallback, useRef } from 'react';

// Kendo
import { useDroppable } from "@progress/kendo-react-common";

export const DroppableBox = (props) => {
    const element = useRef(null);
    const [ isInside, setIsInside ] = useState(false);
    const handleDragEnter = useCallback(() => {
        setIsInside(true);
    }, []);

    const handleDragLeave = useCallback(() => {
        setIsInside(false);
    }, []);

    const handleDrop = useCallback(() => {
        props.onDrop(props.id, props.children );
    }, [ props.onDrop, props.id ]);

    useDroppable(element, {
        onDragEnter: handleDragEnter,
        onDragLeave: handleDragLeave,
        onDrop: handleDrop,
    });

    return (
        <div ref={element} className="square">
            { props.children || "" }
        </div>
    );
};
