// React
import React from 'react';
import { useContext } from 'react';
import { Routes, Route } from 'react-router-dom';

// Global Context
import { GlobalContext } from '../../contexts/Global';

// Components
import Sidebar from '../../components/Sidebar/Sidebar';

// Pages
import UsersList from './pages/List';

const UsersModule = () => {
    const { currentModule, setCurrentModule } = useContext(GlobalContext);

    if (!currentModule || currentModule.id !== 'users') {
        setCurrentModule({
            id: 'users',
            name: 'Utenti'
        });
    }

    return (
        <>
            <Sidebar label={ currentModule.name } />
            <div className="page">
                <Routes>
                    <Route path="/" element={ <UsersList /> } />
                </Routes>
            </div>
        </>
    );
}

export default UsersModule;
