// React
import { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from "react-i18next";

// Global Context
import { GlobalContext } from '../../../contexts/Global';

// Leaflet
import L from "leaflet";
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

// Kendo
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";

// Components
import Title from '../../../components/Page/Title';
import Field from '../../../components/Page/Field';

const CleaningList = () => {
    const { t } = useTranslation();
    const { cleaningCategory = 'collection_point' } = useParams();
    const { user } = useContext(GlobalContext);
    const [ items, setItems ] = useState([]);

    const initialDataState = {
        filter: undefined,
        sort: [
            {
                field: "id",
                dir: "asc"
            }
        ]
    };
    const [ dataState, setDataState ] = useState(initialDataState);
    const [ dataResult, setDataResult ] = useState(
        process(items, dataState)
    );

    const markerIcon = new L.Icon({
        iconUrl: require("../../../assets/icons/cleaning/glass.svg").default,
        iconSize: [ 12, 14 ],
        iconAnchor: [ 6, 7 ]
    });

    useEffect(()=> {
        fetch(process.env.REACT_APP_API_BASE_URL + "/admin/cleaning/" + cleaningCategory, {
            headers: {
                Authorization: 'Bearer ' + user.access_token
            }
        })
            .then((response) => response.json())
            .then((response) => {
                setItems(response.data.cleaning);
                setDataResult(process(response.data.cleaning, dataState));
    });
    }, [ user, setItems, cleaningCategory, dataState ])

    const Coordinates = (props) => {
        let coordinates = '-';
        if (props.dataItem.lat && props.dataItem.lon) {
            coordinates = (
                <>
                    Lat: { props.dataItem.lat.toFixed(5) }<br />Lon: { props.dataItem.lon.toFixed(5) }
                </>
            );
        }
        return (
            <td style={{ whiteSpace: 'nowrap' }}>{ coordinates }</td>
        );
    }

    const Photo = (props) => {
        if (props.dataItem.photos.length > 0) {
            return (
                <td>
                    <img src={ props.dataItem.photos[0].url } alt="" style={{ maxHeight: '100px' }} />
                </td>
            )
        }
        else {
            return (
                <td></td>
            )
        }
    }

    return (
        <>
            <Title>Lista { t('cleaning_categories.' + cleaningCategory) } <small>({ items.length })</small></Title>
            <div className="section grid-template-two-columns">
                <div className="left">
                    <Grid
                        style={{
                            height: "100%",
                        }}
                        className="k-mt-6"
                        scrollable={ true }
                        fixedScroll={ true }
                        sortable={ true }
                        filterable={ false }
                        data={ dataResult }
                        { ...dataState }
                        onDataStateChange={(e) => {
                            setDataState(e.dataState);
                            setDataResult(process(items, e.dataState));
                        }}
                    >
                        <GridColumn field="id" title="Id" width="100px" />
                        <GridColumn field="coordinates" title="Coordinate" cell={ Coordinates } />
                        <GridColumn field="photo" title="Foto" cell={ Photo } width="200px" />
                    </Grid>
                </div>

                <div className="right">
                    <Field label="Mappa" className="k-h-full">
                        <MapContainer center={[ 45.3140617, 9.505 ]} zoom={ 14 } scrollWheelZoom={ true } zoomControl={ false }>
                            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />

                            { items.length > 0 && items.map((item, index) =>
                                <Marker
                                    key={ `marker-${index}` }
                                    position={[ item.lat, item.lon ]}
                                    icon={ markerIcon }
                                >
                                </Marker>
                            )}
                        </MapContainer>
                    </Field>
                </div>
            </div>
        </>
    );
}

export default CleaningList;
