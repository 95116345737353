// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#topbar {
  width: 100%;
  height: 92px;
  background-color: #f9f9f8;
  display: flex;
  flex-direction: row;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0px 36px;
  font-size: 11px;
}
#topbar .logo {
  max-width: 252px;
}
#topbar .logo img {
  max-height: 50px;
}
#topbar .breadcrumb {
  color: #423830;
}
#topbar .menu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  border-right: 1px solid #e9e9e3;
  padding: 0px 20px;
  min-height: 25px;
  align-items: center;
}
#topbar .profile {
  position: relative;
  max-width: 220px;
  cursor: pointer;
}
#topbar .profile .dropdown {
  position: absolute;
  right: 0px;
  top: 100%;
  padding: 17% 10px 10px 120px;
  margin-right: -10px;
  display: none;
  background-color: #f9f9f8;
}
#topbar .profile:hover .dropdown {
  display: block;
}`, "",{"version":3,"sources":["webpack://./src/components/Topbar/Topbar.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,YAAA;EACA,yBAAA;EACA,aAAA;EACA,mBAAA;EACA,cAAA;EACA,mBAAA;EACA,8BAAA;EACA,iBAAA;EACA,eAAA;AACJ;AACI;EACI,gBAAA;AACR;AACQ;EACI,gBAAA;AACZ;AAGI;EACI,cAAA;AADR;AAII;EACI,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,SAAA;EACA,+BAAA;EACA,iBAAA;EACA,gBAAA;EACA,mBAAA;AAFR;AAKI;EACI,kBAAA;EACA,gBAAA;EACA,eAAA;AAHR;AAKQ;EACI,kBAAA;EACA,UAAA;EACA,SAAA;EACA,4BAAA;EACA,mBAAA;EACA,aAAA;EACA,yBAAA;AAHZ;AAOY;EACI,cAAA;AALhB","sourcesContent":["#topbar {\r\n    width: 100%;\r\n    height: 92px;\r\n    background-color: #f9f9f8;\r\n    display: flex;\r\n    flex-direction: row;\r\n    flex-shrink: 0;\r\n    align-items: center;\r\n    justify-content: space-between;\r\n    padding: 0px 36px;\r\n    font-size: 11px;\r\n\r\n    .logo {\r\n        max-width: 252px;\r\n\r\n        img {\r\n            max-height: 50px;\r\n        }\r\n    }\r\n\r\n    .breadcrumb {\r\n        color: #423830;\r\n    }\r\n\r\n    .menu {\r\n        display: flex;\r\n        flex-direction: row;\r\n        justify-content: flex-end;\r\n        gap: 20px;\r\n        border-right: 1px solid #e9e9e3;\r\n        padding: 0px 20px;\r\n        min-height: 25px;\r\n        align-items: center;\r\n    }\r\n\r\n    .profile {\r\n        position: relative;\r\n        max-width: 220px;\r\n        cursor: pointer;\r\n\r\n        .dropdown {\r\n            position: absolute;\r\n            right: 0px;\r\n            top: 100%;\r\n            padding: 17% 10px 10px 120px;\r\n            margin-right: -10px;\r\n            display: none;\r\n            background-color: #f9f9f8;\r\n        }\r\n\r\n        &:hover {\r\n            .dropdown {\r\n                display: block;\r\n            }\r\n        }\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
