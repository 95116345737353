// Styles
import './Sidebar.scss';

const Sidebar = ({ label, children }) => {
    return (
        <div id="sidebar">
            { label &&
                <div className="module-label">{ label }</div>
            }

            { children }
        </div>
    );
}

export default Sidebar;