// React
import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { IntlProvider, load } from "@progress/kendo-react-intl";

// Global Context
import GlobalContextProvider from './contexts/Global';

// I18N
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

// Locale
import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
import weekData from "cldr-core/supplemental/weekData.json";
import itNumbers from "cldr-numbers-full/main/it/numbers.json";
import itCaGregorian from "cldr-dates-full/main/it/ca-gregorian.json";
import itDateFields from "cldr-dates-full/main/it/dateFields.json";

// Components
import App from './App';

// Styles
import './index.scss';

load(
    likelySubtags,
    weekData,
    itNumbers,
    itCaGregorian,
    itDateFields
);

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: "it",
        fallbackLng: "it",
        interpolation: {
            escapeValue: false
        }
    });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <GlobalContextProvider>
        <IntlProvider locale="it">
            <App />
        </IntlProvider>
    </GlobalContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
